import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import useMixinStyles from 'styles/mixins';
import SearchableColumnHeader from './SearchableColumnHeader/SearchableColumnHeader';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import EmptyState from 'components/EmptyState/EmptyState';
import Center from 'components/Center/Center';
import DateColumnHeader from './DateColumnHeader/DateColumnHeader';

const useStyles = makeStyles((theme) => ({
  table: {
    height: '',
  },
  tableHeader: {
    background: 'white',
  },
  tableHeaderCell: {
    background: 'white',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontSize: '0.6875rem',
    borderBottom: 'none',
    color: 'rgba(34, 47, 62, 0.74)',
    letterSpacing: '0.25px',
    padding: '1rem 0.5rem 0.5rem',
    '&:first-of-type': {
      paddingLeft: ({ horizontalPadding }) => horizontalPadding,
    },
    '&:last-of-type': {
      paddingRight: ({ horizontalPadding }) => horizontalPadding,
    },
  },
  tableBody: {
    position: 'relative',
  },
  theight: {
    background: 'rgba(34, 47, 62, 0.02)',
    '&:nth-of-type(even)': {
      background: 'white',
    },
  },
  tcell: {
    fontSize: '0.8125rem',
    padding: '0.5rem 0.5rem',
    borderBottom: 'none',
    color: '#222F3E',
    letterSpacing: '0.325px',
    fontWeight: 500,
    '&:first-of-type': {
      paddingLeft: ({ horizontalPadding }) => horizontalPadding,
    },
    '&:last-of-type': {
      paddingRight: ({ horizontalPadding }) => horizontalPadding,
    },
  },
  loadingIndicator: {
    transform: 'translate(-50%, -50%)',
  },
}));

export default function BasicTable({
  columns,
  rows,
  align = 'center',
  horizontalPadding = '2rem',
  isLoading,
  testRowClass,
}) {
  const classes = useStyles({ horizontalPadding });
  const mixinClasses = useMixinStyles();
  return (
    <Table className={classes.table} stickyHeader>
      <TableHead>
        <TableRow className={classes.tableHeader}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align || align}
              style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
              className={(clsx(classes.tableHeaderCell, mixinClasses.ellipsis), testRowClass)}
            >
              <Box textAlign={column.align || align}>{column.label}</Box>
              {column.withSearch && <SearchableColumnHeader column={column} />}
              {column.withDate && <DateColumnHeader column={column} />}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {!rows?.length && !isLoading && (
        <Center className={classes.loadingIndicator} position="absolute" top="55%" left="50%">
          <EmptyState />
        </Center>
      )}
      <TableBody className={classes.tableBody}>
        {rows?.map((row, index) => {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={`${row.loanNumber}_${index}`}
              className={classes.theight}
            >
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell
                    key={column.id}
                    align={column.align || align}
                    className={clsx(classes.tcell, mixinClasses.ellipsis)}
                    style={{ maxWidth: column.maxWidth }}
                    title={column.withTitle ? value : ''}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        {isLoading && (
          <Box bgcolor="#ffffffc4" position="absolute" top="0" left="0" right="0" bottom="0">
            <Center className={classes.loadingIndicator} position="absolute" top="55%" left="50%">
              <SpinnerWrapped />
            </Center>
          </Box>
        )}
      </TableBody>
    </Table>
  );
}
