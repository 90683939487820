import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppProviders from 'contexts/AppProviders';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <AppProviders>
        <App />
      </AppProviders>
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
