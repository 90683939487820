import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, Box, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import InputSpinner from 'components/Spinner/InputSpinner/InputSpinner';
import useInputStyles from './useInputStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: ({ autoGrow }) => (autoGrow ? 'initial' : 'relative'),
    width: '100%',
    height: ({ fullHeight }) => (fullHeight ? '100%' : 'auto'),
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  inputRoot: {
    '&.MuiInputBase-multiline': {
      padding: 0,
    },
    color: 'inherit',
    width: '100%',
    height: ({ fullHeight }) => (fullHeight ? '100%' : 'auto'),
    position: ({ autoGrow }) => (autoGrow ? 'initial' : 'relative'),
  },
}));

export default function BasicInput({
  placeholder,
  startIcon,
  onChange,
  value,
  label,
  error,
  hideErrors,
  disabled,
  noShadow,
  inputRef,
  loading,
  touched,
  size,
  fullHeight,
  showErrorsWithoutTouch,
  autoGrow,
  dataTestid,
  textAlign,
  ...rest
}) {
  const classes = useStyles({
    fullHeight,
    autoGrow,
  });
  const inputClasses = useInputStyles({
    hasStartIcon: !!startIcon,
    hasLabel: !!label,
    isDisabled: disabled,
    noShadow,
    size,
    fullHeight,
    autoGrow,
    textAlign,
  });
  const inputProps = { ...rest };

  return (
    <FormControl classes={{ root: classes.root }} error={Boolean(error)}>
      <InputLabel shrink className={inputClasses.label}>
        {label}
      </InputLabel>

      <InputBase
        startAdornment={startIcon && <Box className={inputClasses.startIcon}>{startIcon}</Box>}
        value={value === null ? '' : value}
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: inputClasses.inputInput,
        }}
        onChange={onChange}
        disabled={disabled}
        ref={inputRef}
        inputProps={inputProps}
      />
      {loading && <InputSpinner />}
      {!hideErrors && error && (touched || showErrorsWithoutTouch) && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
}
