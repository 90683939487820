import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import ContainedButton from 'components/BasicButton/ContainedButton/ContainedButton';
import { useAddLoan } from 'services/loans/hooks';
import { useHistory } from 'react-router-dom';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useBorrowersFilters } from '../BorrowersFiltersProvider/BorrowersFiltersProvider';

export default function AddBorrower() {
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  const { searchString } = useBorrowersFilters();
  const [mutate, { isLoading }] = useAddLoan({
    onSuccess: (res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCESS) {
        const path = `/borrowers/signed-up/borrowers-details/loan-id/${res.result?.data}`;
        history.push(path);
        setAlertMessage('Account added successfully!');
        setTimeout(() => {});
        return;
      }
      setErrorAlertMessage('Account not found');
    },
  });

  const handleOnAdd = async () => {
    mutate({
      account: searchString,
    });
  };

  return (
    <BasicCard className="__signed_up_card_add__">
      <BasicCardContent>
        <BasicCardTitle color="textSecondary">Account</BasicCardTitle>
        <BasicCardSubtitle>{searchString}</BasicCardSubtitle>
      </BasicCardContent>
      <BasicCardActions>
        <ContainedButton onClick={handleOnAdd} isLoading={isLoading} size="sm">
          Add Account
        </ContainedButton>
      </BasicCardActions>
    </BasicCard>
  );
}
