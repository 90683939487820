import React from 'react';
import BasicTextField from 'components/BasicInputs/BasicTextField/BasicTextField';
import Icon from 'components/Icon/Icon';
import * as JSEncrypt from 'jsencrypt';
import * as yup from 'yup';
import Form from 'components/Form/Form';
import { useLogin } from 'services/auth/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import HomeCard from '../HomeCard/HomeCard';
import HomeCardHeader from '../HomeCard/HomeCardHeader/HomeCardHeader';
import HomeCardForm from '../HomeCard/HomeCardForm/HomeCardForm';
import HomeCardFormInput from '../HomeCard/HomeCardForm/HomeCardFormInput/HomeCardFormInput';
import HomeCardActions from '../HomeCard/HomeCardActions/HomeCardActions';
import { ENV } from '../../../constants';

const validationSchema = yup.object({
  email: yup.string().email('Email address is not valid').required('Email address is required'),
  password: yup.string().required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export default function Login() {
  const { setErrorAlertMessage } = useAlertContext();
  const [mutate] = useLogin({
    onSuccess: (response) => {
      if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
        setErrorAlertMessage(response.message);
      }
    },
  });

  const handleOnSubmit = async (values) => {
    const { email, password } = values;
    const key = new JSEncrypt.JSEncrypt();
    key.setPublicKey(ENV.TOKEN);
    const encryptedPassword = key.encrypt(password);
    await mutate({ email, password: encryptedPassword });
  };

  return (
    <HomeCard>
      <HiddenHeader>Login</HiddenHeader>
      <HomeCardHeader title="Sign In" description="Welcome back!" />
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
      >
        <HomeCardForm>
          <HomeCardFormInput>
            <BasicTextField
              startIcon={<Icon name="mail" />}
              name="email"
              label="Email"
              placeholder="user@gmail.com"
              data-testid="__login_email__"
            />
          </HomeCardFormInput>
          <HomeCardFormInput>
            <BasicTextField
              startIcon={<Icon name="lock" />}
              name="password"
              label="Password"
              placeholder="••••••"
              type="password"
              data-testid="__login_pass__"
            />
          </HomeCardFormInput>
        </HomeCardForm>
        <HomeCardActions
          primaryButtonText="Sign In"
          secondaryButtonText="FORGOT YOUR PASSWORD?"
          secondaryButtonURL="/forgot-password"
        />
      </Form>
    </HomeCard>
  );
}
