import { Box, Grid } from '@material-ui/core';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import React from 'react';
import { useDetails } from 'services/client/hooks';
import SettingsTabHeader from '../SettingsTabHeader/SettingsTabHeader';
import LoginLogo from './LoginLogo/LoginLogo';
import NavbarLogo from './NavbarLogo/NavbarLogo';

export default function CATC() {
  const { isLoading } = useDetails();
  return (
    <Box data-testid="__dt_cap_box__">
      <HiddenHeader>Client Admin Tools Customization</HiddenHeader>
      <SettingsTabHeader
        title="Client Admin Tools Customization"
        description="Customize your client admin platform based on your preferences"
      />
      {isLoading ? (
        <SpinnerWrapped />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <LoginLogo />
          </Grid>
          <Grid item xs={4}>
            <NavbarLogo />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
