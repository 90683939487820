import React from 'react';
import Router from 'config/Router';
import { ReactQueryDevtools } from 'react-query-devtools';

const App = () => (
  <>
    {/* <ReactQueryDevtools /> */}
    <Router />
  </>
);

export default App;
