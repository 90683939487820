import { Box } from '@material-ui/core';
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import React from 'react';
import { useClientDetailsContext } from 'services/client/context';
import PERMISSIONS from 'shared/constants/permissions';
import MicroFrontEnd from '../MicroFrontEnd';

export default function PMS({ config = {} }) {
  const { currentUser, canUserAccessTo } = useCurrentUser();
  const { pms, chatMaxCharacter } = useClientDetailsContext();

  //if the app is running in unit testing mode, we do not render pms because it is an external application
  if (process.env.REACT_APP_RUNNING_TEST) {
    return <Box />;
  }

  return (
    <MicroFrontEnd
      name="PMS"
      host={process.env.REACT_APP_PMS_URL}
      config={{
        username: currentUser.pmsId,
        clientId: pms.pmsUuid,
        clientSecret: pms.pmsSecret,
        inputMaxCharacters: chatMaxCharacter,
        readOnly: canUserAccessTo(PERMISSIONS.CHAT.READ_ONLY),
        baseApiUrl: pms.pmsUrl,
        ...config,
      }}
    />
  );
}
