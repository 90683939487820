import React, { useMemo, useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import Text from 'components/Text/Text';
import useMixinStyles from 'styles/mixins';
import useInputStyles from '../useInputStyles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  icon: {
    color: palette.grey[500],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  paper: {
    '& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: palette.primary[200],
    },
  },
}));

const BasicSelectInput = ({
  children,
  label,
  name,
  hideErrors,
  placeholder,
  noShadow,
  disabled,
  noIcon,
  multiple,
  size,
  ...rest
}) => {
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={`${props.className} ${classes.icon}`} />;
  };
  const mixinStyles = useMixinStyles();
  const [field, meta] = useField({ name });
  const classes = useStyles();
  const inputClasses = useInputStyles({
    hasLabel: !!label,
    noShadow,
    error: !!meta.error && meta.touched,
    isDisabled: disabled,
    size,
  });

  const menuProps = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      classes: {
        paper: classes.paper,
      },
    }),
    [],
  );

  return (
    <FormControl classes={{ root: classes.root }} error={Boolean(meta.error && meta.touched)}>
      <InputLabel shrink className={inputClasses.label}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        displayEmpty
        disableUnderline
        classes={{ root: inputClasses.inputInput, select: mixinStyles.ellipsis }}
        IconComponent={noIcon ? 'br' : iconComponent}
        MenuProps={menuProps}
        renderValue={
          (!multiple && field.value) || field.value === 0 || !!field.value?.length
            ? undefined
            : () => (
              <Text
                  className={mixinStyles.ellipsis}
                  fontSize="0.75rem"
                  fontWeight={500}
                  letterSpacing="0.15px"
                >
                  {placeholder}
                </Text>
              )
        }
        {...field}
        {...rest}
        multiple={multiple}
        value={multiple && !field.value ? [] : field.value}
      >
        {children}
      </Select>
      {!hideErrors && meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default BasicSelectInput;
