import React from 'react';
import { useOnApp } from 'services/borrowers/hooks';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersList from '../BorrowersList/BorrowersList';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import { OnAppCard } from './OnAppCard/OnAppCard';
import OnAppTable from './OnAppTable/OnAppTable';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';

function OnApp() {
  const { data, isFetching } = useOnApp();
  return (
    <BorrowersContainer>
      <HiddenHeader>On App</HiddenHeader>
      <BorrowersFilters
        SearchBar={
          <BorrowersSearchBar
            placeholder="Search By Account Number or User Name"
            isLoading={isFetching}
            dataTestId="__dt_on_app_search__"
          />
        }
        ListTypeToggle={<BorrowersListItemToggle />}
      />
      <BorrowersList
        data={data}
        CardComponent={OnAppCard}
        TableComponent={OnAppTable}
        dataTestId="__dt_borrower_on_app_list__"
      />
      <BorrowersPagination
        count={data?.result?.totalPage}
        dataTestId="__on_app_pagination_box__"
        paginationItemClass="__on_app_pagination__"
      />
    </BorrowersContainer>
  );
}

export default function StatelessSignedUp() {
  return (
    <BorrowersFiltersProvider>
      <OnApp />
    </BorrowersFiltersProvider>
  );
}
