import React, { useEffect } from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardFloatingAction from 'components/BasicCard/BasicCardFloatingAction/BasicCardFloatingAction';
import BasicCardBanner from 'components/BasicCard/BasicCardBanner/BasicCardBanner';
import BasicCardWrapper from 'components/BasicCard/BasicCardWrapper/BasicCardWrapper';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import BasicCardSecondaryContent from 'components/BasicCard/BasicCardContent/BasicCardSecondaryContent/BasicCardSecondaryContent';
import BasicCardCommon from 'components/BasicCard/BasicCardCommon/BasicCardCommon';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import BasicButton from 'components/BasicButton/BasicButton';
import { Box } from '@material-ui/core';
import Icon from 'components/Icon/Icon';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useDisclosure from 'shared/hooks/useDisclosure';
import { useDeleteFailedLink } from 'services/borrowers/hooks';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import AddBorrower from 'pages/Borrowers/LinkedFailed/AddBorrower/AddBorrower';
import { useClientDetailsContext } from 'services/client/context';
import { useQueryCache } from 'react-query';
import { API_RESPONSE_STATUS } from 'shared/constants/http';

export default function LinkedFailedCard({ data }) {
  const queryCache = useQueryCache();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mutate, { isLoading, isError, isSuccess }] = useDeleteFailedLink({
    onSuccess: (response) => {
      if (response?.status === API_RESPONSE_STATUS.SUCCESS) {
        queryCache.invalidateQueries('failed-notify-lenders');
        queryCache.invalidateQueries('app-details');
        onClose();
        setAlertMessage('Success!');
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { isSsn } = useClientDetailsContext();
  const [open, setOpen] = React.useState(false);

  const addBorrower = () => {
    setOpen(true);
  };

  const change = () => {
    setOpen(!open);
  };

  const handleOnDeleteFailedLink = async () => {
    mutate({ notifyLenderId: data.id });
  };

  return (
    <>
      <BasicCard noPadding className="__failed_link_card__">
        <BasicCardFloatingAction onClick={onOpen} />
        <BasicCardBanner>Loan Account Linking Failed</BasicCardBanner>
        <BasicCardWrapper flex={1}>
          <BasicCardContent>
            <BasicCardTitle>{`Account ${data.attemptLoanNo}`}</BasicCardTitle>
            <BasicCardSubtitle>
              {`${data.installAccount.instlFirstName || '- -'} ${
                data.installAccount.instlLastName ? `${data.installAccount.instlLastName}` : ''
              }`}
            </BasicCardSubtitle>
            <BasicCardSecondaryContent>
              <BasicCardCommon attribute="Zip Code" value={data.attemptZipCd} />
              {isSsn && <BasicCardCommon attribute="SSN4" value={data.attemptTinFour || 'N/A'} />}
              <BasicCardCommon
                attribute="Email"
                value={
                  data.installAccount.installPrimaryEmailAddress
                    ? data.installAccount.installPrimaryEmailAddress.emailAddrText
                    : 'N/A'
                }
              />
            </BasicCardSecondaryContent>
          </BasicCardContent>
          <BasicCardActions>
            <BasicButton size="small" fullWidth onClick={addBorrower}>
              <Box display="flex" alignItems="center">
                <Box display="flex" marginRight="0.4375rem">
                  <Icon name="add" />
                </Box>
                Add Borrower
              </Box>
            </BasicButton>
          </BasicCardActions>
        </BasicCardWrapper>
      </BasicCard>
      <ConfirmationDialog
        title="Delete failed link"
        description="Are you sure you want to delete this failed linked loan?"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleOnDeleteFailedLink}
        loading={isLoading}
      />
      {open && (
        <AddBorrower
          open={open}
          change={change}
          loanNo={data.attemptLoanNo}
          loanId={data.id}
          id={data.id}
          zipCode={data.attemptZipCd}
          dob={data.attemptDob}
          ssn={data.attemptTin}
          ssn4={data.attemptTinFour}
          phoneNo={data.attemptPhoneNo}
          branchNo={data.attemptBranchNo}
        />
      )}
    </>
  );
}
