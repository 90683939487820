import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import BasicInput from 'components/BasicInputs/BasicInput';
import { useBorrowersFilters } from 'pages/Borrowers/BorrowersFiltersProvider/BorrowersFiltersProvider';
import { debounce } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: 'relative',
  },
  searchIcon: {
    zIndex: 1,
    position: 'absolute',
    left: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.common.borderInput,
    height: '1.2rem',
    width: '1.5rem',
  },
}));

export default function BorrowersSearchBar({
  placeholder = 'Search By Account Number or Borrower Name',
  isLoading,
  dataTestId,
}) {
  const classes = useStyles();
  const { searchString, setQuery } = useBorrowersFilters();
  const [queryString, setQueryString] = useState(searchString);

  const handleSearchChange = useCallback((value) => {
    if (!value) {
      setQuery({ query: undefined, page: undefined });
      return;
    }
    setQuery({ query: value, page: undefined });
  }, []);

  const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 300), []);

  const handleSearchQueryChange = useCallback((e) => {
    setQueryString(e.target.value);
    debouncedHandleSearchChange(e.target.value);
  }, []);

  useEffect(() => {
    setQueryString(searchString);
  }, [searchString]);

  return (
    <div className={classes.grow}>
      <BasicInput
        value={queryString}
        startIcon={<SearchIcon className={classes.searchIcon} />}
        placeholder={placeholder}
        onChange={handleSearchQueryChange}
        loading={isLoading}
        data-testid={dataTestId}
      />
    </div>
  );
}
