import React from 'react';
import BasicCard from 'components/BasicCard/BasicCard';
import BasicCardContent from 'components/BasicCard/BasicCardContent/BasicCardContent';
import BasicCardTitle from 'components/BasicCard/BasicCardTitle/BasicCardTItle';
import BasicCardSecondaryContent from 'components/BasicCard/BasicCardContent/BasicCardSecondaryContent/BasicCardSecondaryContent';
import BasicCardSubtitle from 'components/BasicCard/BasicCardSubtitle/BasicCardSubtitle';
import BasicCardHighlight from 'components/BasicCard/BasicCardHighlight/BasicCardHighlight';
import CommonTilesActions from 'components/BasicCard/BasicCardActions/CommonTilesActions/CommonTilesActions';
import BasicCardActions from 'components/BasicCard/BasicCardActions/BasicCardActions';
import BasicCardCommon from 'components/BasicCard/BasicCardCommon/BasicCardCommon';
import Icon from 'components/Icon/Icon';
import BasicCardBanner from 'components/BasicCard/BasicCardBanner/BasicCardBanner';
import FlexRow from 'components/FlexRow/FlexRow';
import { Box } from '@material-ui/core';
import useMixinStyles from 'styles/mixins';
import { dateWithSlashesTimezoned } from 'shared/utils';

export default function SignedUpCard({ data }) {
  const mixinStyles = useMixinStyles();
  return (
    <BasicCard className="__signed_up_card__">
      <BasicCardContent>
        <BasicCardTitle>
          <FlexRow position="relative">
            {`Account ${data.loan.loanNo}`}
            {data.deletedAt && (
              <BasicCardBanner
                className={mixinStyles.absoluteCenter}
                left="100%"
                marginLeft="0.5rem"
                borderRadius="8px"
                padding="0.25rem"
              >
                Unlinked
              </BasicCardBanner>
            )}
          </FlexRow>
        </BasicCardTitle>

        <BasicCardSubtitle>
          {`${data.loan.borrower.firstName || '- -'} ${
            data.loan.borrower.lastName ? `${data.loan.borrower.lastName}` : ''
          }`}
        </BasicCardSubtitle>
        <BasicCardSecondaryContent>
          <BasicCardCommon
            attribute={data.deletedAt ? 'Date Unlinked' : 'Date Linked'}
            value={
              data.deletedAt
                ? dateWithSlashesTimezoned(data.deletedAt.date)
                : dateWithSlashesTimezoned(data.createdAt.date)
            }
          />
          <BasicCardCommon
            attribute={data.deletedAt ? 'Unlinked By' : 'Linked By'}
            value={`${data.linkAttempt.installAccount.instlFirstName} ${data.linkAttempt.installAccount.instlLastName}`}
          />
        </BasicCardSecondaryContent>
        <BasicCardHighlight
          icon={<Icon name="calendar" />}
          attribute="Days Past Due"
          value={
            (data.loan.hasOwnProperty('daysPastDue') && data.loan.daysPastDue) ||
            data.loan.daysPastDue === 0
              ? data.loan.daysPastDue
              : '--'
          }
        />
      </BasicCardContent>
      <BasicCardActions>
        <CommonTilesActions data={data} />
      </BasicCardActions>
    </BasicCard>
  );
}
