import React from 'react';
import { SCHEDULED_QPTP_RULES_TABS } from 'shared/constants/rules';
import BasicsTabsContextProvider from 'components/BasicTabs/BasicTabsProvider/BasicTabsProvider';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import RulesCommonLayout from '../RulesCommonLayout/RulesCommonLayout';

const SCHEDULED_QPTP_TABS = [
  {
    index: SCHEDULED_QPTP_RULES_TABS.NOT_ACCEPT,
    title: 'Do not accept a Q-PTP',
  },
  {
    index: SCHEDULED_QPTP_RULES_TABS.NOT_ACCEPT_AFTER_MONTH_END,
    title: 'Do not accept a Q-PTP after month end',
  },
];

const PLATFORMS = [
  {
    value: 1,
    icon: 'web',
    name: 'Web',
  },
  {
    value: 2,
    icon: 'mobile',
    name: 'Mobile',
  },
  {
    value: 3,
    icon: 'sms',
    name: 'SMS',
  },
  {
    value: 4,
    icon: 'phone',
    name: 'IVR',
  },
];

export default function ScheduledQPTPRules() {
  return (
    <BasicsTabsContextProvider initialActiveTab={SCHEDULED_QPTP_TABS[0].index}>
      <HiddenHeader>Scheduling Qualified PTP</HiddenHeader>
      <RulesCommonLayout
        platforms={PLATFORMS}
        tabs={SCHEDULED_QPTP_TABS}
        icon="tax"
        title="Scheduling Qualified PTP Rules"
      />
    </BasicsTabsContextProvider>
  );
}
