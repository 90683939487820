import React from 'react';
import { useSignedUp } from 'services/borrowers/hooks';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import BorrowersContainer from '../BorrowersContainer/BorrowersContainer';
import BorrowersFilters from '../BorrowersFilters/BorrowersFilters';
import BorrowersList from '../BorrowersList/BorrowersList';
import SignedUpCard from './SignedUpCard/SignedUpCard';
import SignedUpTable from './SignedUpTable/SignedUpTable';
import BorrowersSortBy from '../BorrowersFilters/BorrowersSortBy/BorrowersSortBy';
import BorrowersListItemToggle from '../BorrowersFilters/BorrowersListItemToggle/BorrowersListItemToggle';
import BorrowersSearchBar from '../BorrowersFilters/BorrowersSearchBar/BorrowersSearchBar';
import BorrowersPagination from '../BorrowersFilters/BorrowersPagination/BorrowersPagination';
import BorrowersFiltersProvider from '../BorrowersFiltersProvider/BorrowersFiltersProvider';
import BorrowersExportList from '../BorrowersFilters/BorrowersExportList/BorrowersExportList';
import AddBorrower from '../AddBorrower/AddBorrower';

function SignedUp() {
  const { data, isFetching } = useSignedUp();
  return (
    <BorrowersContainer>
      <HiddenHeader data-testid="borrower-list">Signed Up</HiddenHeader>
      <BorrowersFilters
        SearchBar={<BorrowersSearchBar isLoading={isFetching} dataTestId="__dt_search__" />}
        SortBy={<BorrowersSortBy />}
        ListTypeToggle={<BorrowersListItemToggle />}
        ExportBorrowersList={
          <AccessControl perform={PERMISSIONS.BORROWERS.EXPORT_LIST}>
            <BorrowersExportList />
          </AccessControl>
        }
      />
      <BorrowersList
        data={data}
        CardComponent={SignedUpCard}
        TableComponent={SignedUpTable}
        EmptyStateComponent={AddBorrower}
        dataTestId="__dt_borrower_list__"
      />
      <BorrowersPagination
        count={data?.result?.totalPage}
        dataTestId="__signed_up_pagination_box__"
        paginationItemClass="__signed_up_pagination__"
      />
    </BorrowersContainer>
  );
}

export default function StatelessSignedUp() {
  return (
    <BorrowersFiltersProvider>
      <SignedUp />
    </BorrowersFiltersProvider>
  );
}
