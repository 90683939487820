import { usePagination } from 'components/Pagination/PaginationProvider/PaginationProvider';
import { useFormikContext } from 'formik';
import produce from 'immer';
import { useMemo, useEffect, useState } from 'react';

import { useMutation, usePaginatedQuery, useQuery, useQueryCache } from 'react-query';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import useDebounce from 'shared/hooks/useDebounce';
import {
  getBackgroundImage,
  getBackgroundImages,
  getCardFees,
  getClientDetails,
  getDetails,
  updateAddress,
  uploadBackgroundImage,
  uploadClientLogo,
  uploadLogoPlaceholder,
  updateWebsite,
  updatePhone,
  updateColorCode,
  updateTimezone,
  updateTermsText,
  updateAdditionalInformation,
  updatePrivacyPolicy,
  updateVisibleOnApp,
  setSignInImage,
  updateNotifications,
  updateNLS,
  getControlSettings,
  getImportNACHA,
  getTransactions,
  uploadNACHA,
  uploadFavicon,
  updateCAPSettings,
} from './api';

// eslint-disable-next-line import/prefer-default-export
export const useClientDetails = (options = {}) => {
  return useQuery(['client-details'], getClientDetails, {
    staleTime: 1000 * 60 * 5,
    ...options,
  });
};

export const useDetails = () => {
  return useQuery(['details'], getDetails);
};

export const useCardFees = () => {
  return useQuery(['card-fees'], getCardFees);
};

export const useBackgroundImages = () => {
  return useQuery(['background-images'], getBackgroundImages);
};

export const useBackgroundImage = () => {
  return useQuery(['background-image'], getBackgroundImage);
};

export const useUploadClientLogo = (options = {}) => {
  return useMutation(uploadClientLogo, {
    ...options,
  });
};

export const useUploadLogoPlaceholder = (options = {}) => {
  return useMutation(uploadLogoPlaceholder, {
    ...options,
  });
};

export const useUploadFavicon = (options = {}) => {
  return useMutation(uploadFavicon, {
    ...options,
  });
};

export const useUploadBackgroundImage = (options = {}) => {
  return useMutation(uploadBackgroundImage, {
    ...options,
  });
};

export const useUpdateAddress = (options = {}) => {
  return useMutation(updateAddress, {
    ...options,
  });
};

export const useUpdateWebsite = (options = {}) => {
  return useMutation(updateWebsite, {
    ...options,
  });
};

export const useUpdatePhone = (options = {}) => {
  return useMutation(updatePhone, {
    ...options,
  });
};

export const useUpdateColorCode = (options = {}) => {
  return useMutation(updateColorCode, {
    ...options,
  });
};

export const useUpdateTimezone = (options = {}) => {
  return useMutation(updateTimezone, {
    ...options,
  });
};

export const useUpdateTermsText = (options = {}) => {
  return useMutation(updateTermsText, {
    ...options,
  });
};

export const useUpdateAdditionalInformation = (options = {}) => {
  return useMutation(updateAdditionalInformation, {
    ...options,
  });
};

export const useUpdatePrivacyPolicy = (options = {}) => {
  return useMutation(updatePrivacyPolicy, {
    ...options,
  });
};

export const useSetSignInImage = (options = {}) => {
  return useMutation(setSignInImage, {
    ...options,
  });
};

export const useUpdateVisibleOnApp = (options = {}) => {
  const { setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  return useMutation(updateVisibleOnApp, {
    ...options,
    onMutate: ({ status }) => {
      const previousData = queryCache.getQueryData('details');

      queryCache.setQueryData('details', (old) => {
        const updatedDetails = produce(old, (draft) => {
          draft[options.statusName] = status ? '1' : '0';
        });
        return updatedDetails;
      });
      setAlertMessage('Status updated successfully!');

      return () => queryCache.setQueryData('details', previousData);
    },
    onSettled: () => {
      queryCache.invalidateQueries('details');
    },
  });
};

export const useUpdateNotifications = (options = {}) => {
  const { setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  return useMutation(updateNotifications, {
    ...options,
    onMutate: ({ dueDate, expDate }) => {
      const previousData = queryCache.getQueryData('details');

      queryCache.setQueryData('details', (old) => {
        const updatedDetails = produce(old, (draft) => {
          draft.notifyBeforeDueDate = dueDate;
          draft.notifyBeforeCardExp = expDate;
        });
        return updatedDetails;
      });
      setAlertMessage('Notifications updated successfully');

      return () => queryCache.setQueryData('details', previousData);
    },
    onSettled: () => {
      queryCache.invalidateQueries('details');
    },
  });
};

export const useControlSettings = () => {
  return useQuery(['control-settings'], getControlSettings);
};

export const useImportNACHA = () => {
  return useQuery(['import-nacha'], getImportNACHA);
};

export const useUpdateNLS = (options = {}) => {
  const { setAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  return useMutation(updateNLS, {
    ...options,
    onMutate: ({ nlsPostProfile }) => {
      const previousData = queryCache.getQueryData('control-settings');

      queryCache.setQueryData('control-settings', (old) => {
        const updatedDetails = produce(old, (draft) => {
          draft.nlsPostProfile = nlsPostProfile;
        });
        return updatedDetails;
      });
      setAlertMessage('NLS updated successfully!');

      return () => queryCache.setQueryData('control-settings', previousData);
    },
    onSettled: () => {
      queryCache.invalidateQueries('control-settings');
    },
  });
};

export const useTransactions = () => {
  const { itemsPerPage, data } = usePagination();

  const {
    loanNo = null,
    transDate = null,
    amt = null,
    authId = null,
    orderId = null,
    firstName = null,
    lastName = null,
    lastFour = null,
    type = null,
    pmtType = null,
  } = data.payload;

  return usePaginatedQuery(
    [
      'transactions',
      {
        page: data.page,
        limit: itemsPerPage,
        loanNo,
        transDate,
        amt,
        authId,
        orderId,
        firstName,
        lastName,
        lastFour,
        type,
        pmtType,
      },
    ],
    getTransactions,
  );
};

export const useUploadNACHA = (options = {}) => {
  return useMutation(uploadNACHA, {
    ...options,
  });
};

export const useUpdateCAPSettings = (options = {}) => {
  return useMutation(updateCAPSettings, {
    ...options,
  });
};
