import { Box } from '@material-ui/core';
import React from 'react';

export default function HiddenHeader({ children }) {
  return (
    <Box
      component="h1"
      position="absolute"
      left="-10000px"
      top="auto"
      width="1px"
      height="1px"
      overflow="hidden"
    >
      {children}
    </Box>
  );
}
