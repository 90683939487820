import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  label: {
    zIndex: 1,
    left: '16px',
    top: '8px',
    fontSize: '0.875rem',
    letterSpacing: '0.15px',
    color: 'rgba(124, 141, 160, 0.88)',
    '&.Mui-focused': {
      color: 'rgba(124, 141, 160, 0.88)', // to overwrite the default behaviour
    },
  },
  select: {
    boxShadow: '0px 0px 8px rgba(34, 47, 62, 0.06)',
    minWidth: '160px',
    boxSizing: 'border-box',
    height: '3rem',
    background: 'white',
    color: palette.text.primary,
    borderColor: palette.common.border,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '8px',
    paddingLeft: '16px',
    paddingTop: '21px',
    paddingBottom: '8px',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.15px',
    '&:focus': {
      borderRadius: '8px',
      background: 'white',
    },
  },
  icon: {
    color: palette.grey[500],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const BasicSelectBase = ({ onChange, children, label, defaultValue, ...rest }) => {
  const classes = useStyles();
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={`${props.className} ${classes.icon}`} />;
  };
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    onChange(event.target.value);
    setValue(event.target.value);
  };

  return (
    <FormControl classes={{ root: classes.root }}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        disableUnderline
        classes={{ root: classes.select }}
        labelId="inputLabel"
        IconComponent={iconComponent}
        MenuProps={menuProps}
        onChange={handleChange}
        value={value}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default BasicSelectBase;
