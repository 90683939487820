import { Box } from '@material-ui/core';
import Form from 'components/Form/Form';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import React from 'react';
import { useQueryCache } from 'react-query';
import { Redirect } from 'react-router-dom';
import { useClientDetailsContext } from 'services/client/context';
import { useUploadNACHA } from 'services/client/hooks';
import { API_RESPONSE_STATUS } from 'shared/constants/http';
import { useAlertContext } from 'shared/contexts/useAlertContext';
import { buildFormData } from 'shared/utils';
import * as yup from 'yup';
import SettingsTabHeader from '../SettingsTabHeader/SettingsTabHeader';
import NACHAUploadHeader from './NACHAUploadHeader/NACHAUploadHeader';
import NACHAUploadTable from './NACHAUploadTable/NACHAUploadTable';

const initialValues = {
  nickname: '',
  file: '',
};

const VALID_FORMATS = ['text/plain'];

function checkIfFileFormatIsValid(file) {
  let valid = true;
  if (file) {
    if (!VALID_FORMATS.includes(file.type)) {
      valid = false;
    }
  }
  return valid;
}

const validationSchema = yup.object({
  file: yup
    .mixed()
    .required('File is required')
    .test('is-correct-file', 'Only .txt file formats are supported', checkIfFileFormatIsValid),
});

export default function NACHAUpload() {
  const { nachaEnable } = useClientDetailsContext();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const queryCache = useQueryCache();
  const [mutate] = useUploadNACHA({
    onSuccess: (response) => {
      if (response.status === API_RESPONSE_STATUS.SUCCESS) {
        setAlertMessage('NACHA file uploaded successfully');
        queryCache.invalidateQueries('import-nacha');
        return;
      }
      setErrorAlertMessage(response.message);
    },
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    buildFormData(formData, {
      addText: values.file,
      type: 'text',
      filename: values.nickname,
    });
    await mutate(formData);
    resetForm();
  };

  if (!nachaEnable) return <Redirect to="/" />;
  return (
    <Box data-testid="__settings_nacha__">
      <HiddenHeader>NACHA</HiddenHeader>
      <SettingsTabHeader title="NACHA Upload" />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Box marginBottom="1.75rem">
          <NACHAUploadHeader />
        </Box>
        <NACHAUploadTable />
      </Form>
    </Box>
  );
}
