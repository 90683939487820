import React from 'react';
import Container from 'components/Container/Container';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from 'components/RouteWithSubroutes/RouteWithSubroutes';
import SecondNavBar from 'containers/navigation/SecondNavBar';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';

const GROUPS_TABS = [{ name: 'Groups', value: 0, path: '/groups' }];

export default function Groups({ routes }) {
  return (
    <AccessControl perform={PERMISSIONS.GROUPS.VISIT} redirectOnNoAccessTo="/">
      <HiddenHeader>Groups</HiddenHeader>
      <SecondNavBar justifyContent="flex-start" counts={GROUPS_TABS} />
      <Container marginTop="-2rem" height="calc(100vh - 229px)" overflow="hidden">
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
        </Switch>
      </Container>
    </AccessControl>
  );
}
