import { Box, Grid } from '@material-ui/core';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import React from 'react';
import {
  useBackgroundImage,
  useBackgroundImages,
  useCardFees,
  useControlSettings,
  useDetails,
} from 'services/client/hooks';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';
import SettingsTabHeader from '../SettingsTabHeader/SettingsTabHeader';
import AccentColor from './AccentColor/AccentColor';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import ClientLogo from './ClientLogo/ClientLogo';
import Favicon from './Favicon/Favicon';
import Information from './Information/Information';
import LogoPlaceholder from './LogoPlaceholder/LogoPlaceholder';
import PrivacyPolicy from './PrivacyPolicy/PricacyPolicy';
import SignInImage from './SignInImage/SignInImage';
import TermsText from './TermsText/TermsText';
import Timezone from './Timezone/Timezone';
import Address from './Address/Address';
import NLSUpdate from './NLSUpdate/NLSUpdate';

export default function BTC() {
  const { isLoading } = useDetails();
  const { isLoading: isControlSettingsLoading } = useControlSettings();
  const { isLoading: isCardFeesLoading } = useCardFees();
  const { isLoading: isBackgroundImagesLoading } = useBackgroundImages();
  const { isLoading: isBackgroundImageLoading } = useBackgroundImage();
  return (
    <Box>
      <HiddenHeader>Borrower Tools Customization</HiddenHeader>
      <SettingsTabHeader
        title="Borrower Tools Customization"
        description="Customize your plataform based on your preferences"
      />
      {isLoading ||
      isCardFeesLoading ||
      isBackgroundImagesLoading ||
      isBackgroundImageLoading ||
      isControlSettingsLoading ? (
        <SpinnerWrapped />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <ClientLogo />
          </Grid>
          <Grid item xs={4}>
            <LogoPlaceholder />
          </Grid>
          <Grid item xs={4}>
            <Favicon />
          </Grid>
          <Grid item xs={6}>
            <SignInImage />
          </Grid>
          <Grid item xs={6}>
            <Address />
          </Grid>
          <Grid item xs={12}>
            <Information />
          </Grid>
          <Grid item xs={6}>
            <AccentColor />
          </Grid>
          <Grid item xs={6}>
            <TermsText />
          </Grid>
          <Grid item xs={6}>
            <Timezone />
          </Grid>
          <Grid item xs={6}>
            <PrivacyPolicy />
          </Grid>
          <Grid item xs={12}>
            <NLSUpdate />
          </Grid>
          <Grid item xs={12}>
            <AdditionalInformation />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
