import React, { useEffect } from 'react';
import { useBorrowerDetails } from 'services/loans/hooks';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BasicCard from 'components/BasicCard/BasicCard';
import { Divider } from '@material-ui/core';
import LoanData from 'pages/Borrowers/LoanDetails/detailButtons/LoanData';
import DetailButtons from 'pages/Borrowers/LoanDetails/detailButtons/DetailButtons';
import DetailsUserInfo from 'pages/Borrowers/LoanDetails/detailButtons/DetailUserInfo';
import ReceiptsView from 'components/borrowers/payments/ReceiptsView';
import SpinnerWrapped from 'components/Spinner/SpinnerWrapped/SpinnerContainer';
import LoanDetails from 'pages/Borrowers/LoanDetails/detailButtons/LoanDetails';
import { WebPopSwitchProvider } from 'components/WebPop/WebPopSwitchProvider/WebPopSwitchProvider';
import AccessControl from 'components/AccessControl/AccessControl';
import PERMISSIONS from 'shared/constants/permissions';
import { storeAPIUser } from 'shared/utils/authToken';
import { useAPIUser } from 'services/users/hooks';
import HiddenHeader from 'components/HiddenHeader/HiddenHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function LoanDetailsPage() {
  const { data: borrowerDetails, isLoading } = useBorrowerDetails();
  const { data, isLoading: isAPIUserLoading } = useAPIUser();
  useEffect(() => {
    if (data) {
      storeAPIUser(data.result.Credentials);
    }
  }, [data]);
  const classes = useStyles();

  return (
    <WebPopSwitchProvider>
      {isLoading || isAPIUserLoading ? (
        <SpinnerWrapped />
      ) : (
        <div className={classes.root}>
          <HiddenHeader>Loan Details</HiddenHeader>
          <Grid container spacing={4}>
            <Grid className={classes.loanDetailsCard} item xs={12} md={4}>
              <BasicCard noPadding>
                <LoanData />
                <DetailButtons />
                <Divider />
                <LoanDetails />
                <Divider />
                <DetailsUserInfo />
              </BasicCard>
            </Grid>
            <Grid item xs={12} md={8}>
              <ReceiptsView />
            </Grid>
          </Grid>
        </div>
      )}
    </WebPopSwitchProvider>
  );
}

export default function LoanDetailsAccessControled(params) {
  return (
    <AccessControl perform={PERMISSIONS.BORROWERS.DETAILS} redirectOnNoAccessTo="/borrowers">
      <LoanDetailsPage {...params} />
    </AccessControl>
  );
}
