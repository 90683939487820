import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import WhiteLabelLogo from 'assets/img/white-label-logo.svg';
import { useClientDetailsContext } from 'services/client/context';

const whiteLabelPseudoLines = (theme) => ({
  content: '""',
  height: '1px',
  width: '30%',
  background: 'rgba(34, 47, 62, 0.15)',
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});
const whiteLabelPseudoLinesRight = (theme) => ({
  content: '""',
  height: '1px',
  width: '30%',
  background: 'rgba(34, 47, 62, 0.15)',
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});

const useStyles = makeStyles((theme) => ({
  whiteLabelLogo: {
    position: 'relative',
    '&::before': whiteLabelPseudoLines(theme),
    '&::after': whiteLabelPseudoLinesRight(theme),
  },
}));

export default function HomeCard({ children }) {
  const { capCustomizationSettings } = useClientDetailsContext();
  const classes = useStyles();
  return (
    <Box
      padding="3rem"
      borderRadius="0.5rem"
      border="0.1px solid rgba(34, 47, 62, 0.21)"
      bgcolor="#fffffff2"
      width="28.75rem"
      minHeight="31.5rem"
      display="flex"
      flexDirection="column"
      data-testid="__home_card__"
    >
      <Box
        marginBottom="2rem"
        justifyContent="center"
        className={classes.whiteLabelLogo}
        display="flex"
      >
        <Box
          component="img"
          height="2rem"
          src={capCustomizationSettings?.imageLoginLogo || WhiteLabelLogo}
        />
      </Box>
      {children}
    </Box>
  );
}
